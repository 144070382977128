.panel-custom {
  margin-top: 1rem;
  margin-bottom: 1rem;

  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

  .p-card-header {
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    background: #f9fafb;
    color: #374151;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .card-text-header {
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
  .p-card-body {
    border: 1px solid #e5e7eb;
    background: #fff;
    color: #4b5563;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top: 0 none;
  }
}
