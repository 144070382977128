.tiempos-centro-perdidos {
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espaço entre linhas */
  }
  
  .form-row {
    display: flex;
    gap: 20px; /* Espaço entre colunas */
    flex-wrap: wrap; /* Quebra para próxima linha em telas menores */
  }
  
  .sipco-option {
    display: flex;
    flex-direction: column;
    flex: 1; /* Todas as colunas ocupam espaço igual */
    max-width: 300px; /* Limita o tamanho máximo da coluna */
    min-width: 200px; /* Evita que encolham muito em telas pequenas */
    gap: 10px; /* Espaço entre elementos dentro da coluna */
    box-sizing: border-box; /* Inclui padding e bordas no tamanho */
  }
  
  label {
    margin-bottom: 5px; /* Espaço abaixo do rótulo */
  }
  
  .checkbox-row {
    display: flex;
    align-items: center;
    gap: 10px; /* Espaço entre checkbox e texto */
  }
  
}
