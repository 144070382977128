.despacho-mercado-exportacion {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

    //TODO: verificar com santiago outra alternativa
    .p-datatable {
      display: grid;
    }

  .p-fieldset-legend {
    // Edita o estilo do header do fieldset
    // width: 15%; // tamanho da caixa
    font-size: 1.3rem; // tamanho da fonte
  }

  .filter-options {
    display: flex;
    flex-direction: column; /* Faz com que as linhas fiquem uma em cima da outra */
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row; /* Mantém os elementos dentro da linha em linha */
      width: 100%;
      gap: 2rem; /* Ajuste o gap conforme necessário */
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 75%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }
    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }
    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      label {
        gap: 0.5rem;
      }
    }

    .sipco-option-flex-end {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      label {
        gap: 0.5rem;
      }
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .filter-options-column {
    display: flex;
    flex-direction: row; /* Altera para colunas lado a lado */
    gap: 2rem;
    padding: 2rem;
    align-items: flex-start;

    .sipco-options-column {
      display: flex;
      flex-direction: column; /* Mantém os elementos dentro da linha em coluna */
      flex-grow: 1;
      width: 30%; /* Ajuste conforme necessário para ter 3 colunas */
      gap: 2rem; /* Ajuste o gap conforme necessário */
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }

    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 62%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 75%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }

      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }

      label {
        gap: 0.5rem;
      }
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .divide {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%; /* Aproximadamente metade do espaço disponível */
    max-width: 50%; /* Garante que a .divide não ocupe mais que metade do espaço disponível */
    gap: 1rem; /* Espaço entre as .sipco-option-divide */

    .sipco-option-divide {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 0.5rem; /* Espaço interno entre o conteúdo do .sipco-option-divide */
    }
  }

  // .chips {
  //   display: flex;
    .sipco-chip {
      flex-direction: row;
      padding: 1rem;
      gap: 2rem;
      .p-chip {
        margin-left: 1rem;
        min-width: 5rem;
      }
    }
  // }

  .header-data-table {
    display: flex;
    justify-content: space-between;
  }

  .sipco-option-text-small {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    max-width: 10%;
    flex-shrink: 1;
    flex-basis: 0%;
    justify-content: flex-end;
  }
}
