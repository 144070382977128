.contabilizar-viajes-terceros {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
    }
  }

  .datos-recepcion {
    margin-top: 5px;
  }
}

.contabilizar-viajes-terceros-dialog {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

      .sipco-option-check {
        display: flex;
        gap: 5px;
        flex: 3;
        max-width: 180px;
      }
    }
  }

  .selected-row-bold {
    font-weight: bold;
  }

  .tag-registros-generar-prest-contable {
    margin-top: 15px;
    margin-right: 5px;
    background-color: #1a0af0;
  }

  .tag-registros-por-anular {
    margin-top: 15px;
    margin-right: 5px;
    background-color: #8b0000;
  }

  .panel-compra {
    margin-top: 10px;
  }

  .panel-venta {
    margin-top: 10px;
  }

  .dt-compra {
    margin-bottom: 10px;
  }

  .dt-venta {
    margin-bottom: 10px;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }
}

.contabilizar-viajes-terceros-dialog .form-row-center {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contabilizar-viajes-terceros-dialog .form-row-center .sipco-option {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  max-width: 300px;
}

.contabilizar-viajes-terceros-dialog .form-row-center .sipco-option label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.contabilizar-viajes-terceros-dialog .tag-registros-generar-prest-contable {
  margin-top: 30px;
}
.contabilizar-viajes-terceros-dialog .tag-registros-por-anular {
  margin-top: 30px;
}
