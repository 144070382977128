.modificacion-masiva-motor {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
    }
  }

  .chips {
    display: flex;
    .sipco-chip {
      flex-direction: row;
      padding: 1rem;
      gap: 2rem;
      .p-chip {
        margin-left: 1rem;
        min-width: 5rem;
      }
    }
  }
}
