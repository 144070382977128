.devolucion-man {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

      .sipco-option.vin-highlight {
        flex: 2;
        max-width: 600px;
        font-weight: bold;
        border: 1px solid #d8d8d8;
        padding: 10px;
        border-radius: 8px;
      }

      .sipco-option.vin-highlight label {
        font-size: 1.2rem;
      }

      .sipco-option-not-label {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
    }
  }

  fieldset {
    margin-bottom: 10px;
  }
}
