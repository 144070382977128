.migracion-clientes {
  .panel-container {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }

  .left-panel {
    flex: 1;
    min-width: 300px;
  }

  .right-panel {
    flex: 1;
    min-width: 300px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
    }

    .checkbox-row {
      display: flex;
      align-items: center;
      gap: 7px;
      margin-top: 15px;
    }
  }

  .highlight-row {
    background-color: #ecfeff;
  }

  .x-row {
    color: red;
  }
}
