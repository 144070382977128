@font-face {
  font-family: "Roboto";
  src: url("themes/fonts/Roboto-Regular.ttf") format("truetype");
}

* {
  font-family: "Roboto", "sans-serif";
}

html,
body,
#root {
  font-family: "Roboto", "sans-serif";
  height: 100%;
}
