.gestion-preparacion {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: flex-end;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .sipco-option-search {
        display: flex;
        .button {
          align-items: flex-end;
        }
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
