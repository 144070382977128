.carga-danos {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

  .p-panel-footer {
    .p-accordion-header-text {
      display: flex;
      align-items: center;
      height: 3rem;
    }

    .p-accordion-header-link > span > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .p-accordion-header-link {
      padding: 0.5rem 1.5rem;
    }

    .p-tag {
      font-size: 1rem;
    }

    .filter-options {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      align-items: stretch;
      justify-content: flex-start;

      &.collumn {
        flex-direction: column;
      }

      .filter-row {
        display: flex;
        flex-direction: row;
        flex: 1;
        gap: 2rem;
      }

      .filter-options-left,
      .filter-options-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        flex-grow: 1;
        max-width: 30%;
        flex-shrink: 1;
        flex-basis: 30%;

        .sipco-option {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 0.5rem;
          flex-grow: 1;
          max-width: 100%;
          flex-shrink: 1;
          flex-basis: 100%;

          .p-autocomplete-multiple-container.p-component.p-inputtext {
            width: 100%;
            height: 3rem; /* Ajuste para o tamanho desejado */
            overflow: auto;
            flex-grow: 1;
          }

          .p-autocomplete-input.p-inputtext.p-component {
            width: 100%;
            height: 3rem; /* Ajuste para o tamanho desejado */
            overflow: auto;
            flex-grow: 1;
          }

          label {
            gap: 0.5rem;
            flex-grow: 1;
          }
        }

        .sipco-option-line {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          .sipco-option {
            flex: 1; /* Ajuste para garantir que cada opção cresça igualmente */
          }
        }
      }

      .sipco-teste {
        display: flex;
        gap: 1rem;
      }

      .RadioButtonFilter {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .buttons {
      margin-top: 2rem;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
    }
  }
}
