.reingreso-stock {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

      .sipco-option-vin {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
        margin-bottom: 7px;
      }
    }
  }

  .panel-container {
    display: flex;
    gap: 20px;
  }

  .left-panel {
    flex: 1;
  }

  .right-panel {
    flex: 1;
  }

  .right-panel-detail {
    margin-top: 10px !important;  
  }

  .header-container {
    display: flex;
    align-items: center;
    gap: 8px; 
  }

  .required {
    color: red;
    margin-right: 4px;
  }
}
