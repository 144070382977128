.main-layout {
  display: flex;
  height: 100%;
  flex-direction: column;

  .body {
    position: relative;
    display: flex;
    height: 85vh;

    .page-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .page-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 3rem 3rem;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
  }
  .p-panel.p-component {
    margin: 0;
    .p-datatable.p-component.p-datatable-responsive-scroll {
      margin: 0;
      .p-datatable-wrapper {
        margin: 0;
        .p-datatable-table {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  .p-filter-column {
    position: relative;
  }
  .p-column-filter-overlay.p-component.p-fluid.p-ripple-disabled.p-connected-overlay-enter-done {
    top: 0 !important;
  }

  .p-column-filter-menu-button {
    width: 4rem;
    heigth: 4rem;
  }

  .p-datatable-footer {
    .p-button-icon-only {
      justify-content: normal;
      .p-button-icon {
        font-size: 1.5rem;
        margin: 0 7px;
      }
    }
  }
}
@media (max-width: 960px) {
  .main-layout .body {
    height: 85vh;
  }
}

.loading-security-filters-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}

body.loading {
  overflow: hidden;
}
