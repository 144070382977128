.mantener-usuarios{


    .sipco-option {
        display: flex;
        justify-content: left;
        align-items: center;
        padding-right: 25px;
        max-width: 27%;
        gap: 6px;
      }

      .organizeHorizontally{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding-bottom: 25px;
        gap: 5px;
        flex-wrap: wrap;
      }

      .orgHor {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        justify-content: space-between;
      }

      .p-datatable-loading-overlay {
        background-color: rgba(240, 240, 240, 0.5);
        z-index: 10;
      }
    
      .p-datatable-loading-icon {
        color: rgb(180, 180, 180);
        font-size: 2rem;
      }
}