.nav-menu {
  background-color: #18315a;
  z-index: 1000;
  height: 50px;
  .p-submenu-list {
    width: 280px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  }
  .p-menubar.p-component {
    border: none;
    background-color: #0c2856 !important;
  }

  .p-menuitem-text {
    color: white;
  }
  .p-submenu-list * {
    color: #0c2856;
  }

  .p-menuitem-content:hover * {
    border: none;
    display: flex;
    align-items: center;
  }

  .p-menuitem * {
    background-color: #0c2856;
    color: white;
  }
}

@media (max-width: 960px) {
  .nav-menu {
    .p-menuitem * {
      background-color: white;
      color: white;
    }
    .p-menubar.p-menubar-root-list {
      height: 80vh;
      padding: 0;
    }
    .p-menuitem-text {
      font-size: 14px;
      color: black;
    }
    .p-submenu-list {
      width: 100vw;
      height: 100%;
      max-height: 100%;
    }

    .p-menuitem-content:hover * {
      background-color: #0c2856;
      color: white;
    }
    .menu-item.p-menuitem {
      border: 1px solid #0c2856;
    }
  }
}
