.generic-abm {
  .data-row-inactive {
    color: red;
    font-weight: bold;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
.generic-abm-dialog {
  width: 600px;
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .generic-abm-list-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .generic-abm-list-box-actions {
          display: flex;
          gap: 5px;
          justify-content: flex-end;
        }
      }

      &.radio-button {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
      }

      .radio-options-container {
        display: flex;
        flex-direction: row;
        gap: 15px;
        flex-wrap: wrap;
      }
      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
