footer {
  background-color: white;
  align-content: center;
  position: absolute;
  bottom: -4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 20px;
  max-height: 3vh;
}
.p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
