.administracion-remitos-ficticio {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 15px;
      align-items: stretch;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
        justify-content: flex-start;
      }

      .sipcoCalendar {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        gap: 10px;
      }

      .checkboxSipco {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
      }

      .radioButton {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
      }

      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      /* Adicionando as cores com base nos códigos com prefixo 'color-' */
      
    }
  }
  .color-FF0000 {
    color: #ff0000;
  }

  .color-0000FF {
    color: #0000ff;
  }

  .color-C0C000 {
    color: #c0c000;
  }

  .color-00AA00 {
    color: #00aa00;
  }

  .color-FF8000 {
    color: #ff8000;
  }

  .color-808000 {
    color: #808000;
  }

  .color-800040 {
    color: #800040;
  }
}
