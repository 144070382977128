.ingreso-ecom {
  .sipco-option-button {
    align-content: flex-end;
  }
  .sipco-option-small {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-grow: 1;
    max-width: 15%;
    flex-basis: 15%;
    flex-shrink: 1;

    .p-autocomplete-multiple-container.p-component.p-inputtext {
      width: 100%;
      height: 3rem; /* Ajuste para o tamanho desejado */
      overflow: auto;
    }
    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem; /* Ajuste para o tamanho desejado */
      overflow: auto;
    }
    label {
      gap: 0.5rem;
    }
  }
  .panel-content {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    border: 1px solid #e5e7eb;
    background: #f9fafb;
    color: #4b5563;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-top: -6px;
    gap: 20px;

    .form {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      .form-row {
        display: flex;
        gap: 10px;
        flex: 1;
        justify-content: center;
        .input-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          max-width: 300px;
        }
      }
    }

    .form-actions {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }
}
