.reimpresion-remitos {
  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 2rem;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 20%;
      flex-shrink: 1;
      flex-basis: 20%;
      margin-top: auto;
    }
  }

  .dt-descripcion {
    text-align: left;
    margin-bottom: 20px;
  }

  .chips {
    display: flex;
    .sipco-chip {
      flex-direction: row;
      padding: 1rem;
      gap: 2rem;
      .p-chip {
        margin-left: 1rem;
        min-width: 5rem;
      }
    }
  }
}
