.vale-repuestos .highlight-row {
  background-color: #ecfeff;
  cursor: pointer;
}
.p-datatable {
  display: grid;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
    .p-datatable-tbody
  )
  .p-datatable-tbody
  > tr
  > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-width: 0 0 1px 1px;
  max-width: 150px;
}

.vale-repuestos {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
