.login-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  // background-color: #9929bd;

  .content {
    height: 100%;
    width: 100%;

    .body {
      height: 100%;
      width: 100%;
    }
  }
}
