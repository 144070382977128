.ImpressionHojaRuta {
  .color-posee-carga-compartida {
    font-weight: bold;
    color: #047004;
  }

  .tag-posee-carga-compartida {
    margin-right: 5px;
    background-color: #047004;
  }
}
