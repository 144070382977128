.consulta-vin .highlight-row {
  background-color: #ecfeff;
  cursor: pointer;
}

.carga-masiva-danos {
  .vin-view {
    .form-collumn {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .form-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  
    .sipco-option {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      gap: 0.5rem;
    }

    .form-field {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  
    /* Definir largura padrão para as labels */
    .sipco-option label {
      width: 100px; /* Ajuste conforme necessário */
    }

    /* Remover largura específica para as labels de Valor e Importe */
    .sipco-option label.valor,
    .sipco-option label.importe {

      width: auto; /* Remove largura fixa */
      margin-right: 0; /* Remove qualquer margem extra */
    }

    
  }
  

  .VinViewDatos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: inherit;
    height: 100%;

    .FechasTab.p-panel.p-component {
      width: 50%;
      height: 100%;

      .p-panel-content {
        width: 100%;
        height: 100%;
      }

      .p-toggleable-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .DivFechas {
          width: 45%;
          height: 100%;

          .Datos {
            width: 100%;
            height: 100%;
            margin: 5px;
          }
        }
      }
    }

    .DatosTabs.p-panel.p-component {
      width: 50%;
      height: 100%;
    }

    .DivUbicacion {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .Datos {
        width: 100%;
      }
    }
  }

  .MoveHistory {
    .color-despacho {
      font-weight: bold;
      color: #ff0000;
    }

    .color-remito {
      font-weight: bold;
      color: #0000ff;
    }

    .color-remito-error {
      font-weight: bold;
      color: #c0c000;
    }

    .color-ingreso {
      font-weight: bold;
      color: #00aa00;
    }

    .color-ingreso-taller {
      font-weight: bold;
      color: #ff8000;
    }

    .color-ingreso-por-saneamiento {
      font-weight: bold;
      color: #808000;
    }

    .color-ingreso-saneamiento-12-meses {
      font-weight: bold;
      color: #800040;
    }

    .tag-despacho {
      margin-right: 5px;
      background-color: #ff0000;
    }

    .tag-remitos {
      margin-right: 5px;
      background-color: #0000ff;
    }

    .tag-remito-error {
      margin-right: 5px;
      background-color: #c0c000;
    }

    .tag-ingreso {
      margin-right: 5px;
      background-color: #00aa00;
    }

    .tag-ingreso-taller {
      margin-right: 5px;
      background-color: #ff8000;
    }

    .tag-ingreso-por-saneamiento {
      margin-right: 5px;
      background-color: #808000;
    }

    .tag-ingreso-por-saneamiento-12-meses {
      margin-right: 5px;
      background-color: #800040;
    }
  }

  .DamageHistory {
    .repaired {
      font-weight: bold;
      color: green;
    }

    .pending-repair {
      font-weight: bold;
      color: #ff0000;
    }

    .tag-repaired {
      margin-right: 5px;
      background-color: green;
    }

    // .tag-pending-repair {
    //   margin-right: 5px;
    //   background-color: #ff0000;
    // }
  }

  .Messages {
    .color-recibidos {
      font-weight: bold;
      color: #ff0000;
    }
  }

  .Operations {
    .color-operaciones-pendientes-bloqueantes {
      font-weight: bold;
      color: #ff0000;
    }

    .color-operaciones-pendientes {
      font-weight: bold;
      color: #0000ff;
    }
  }

  @media (max-width: 960px) {
    .VinViewDatos {
      #pr_id_6_content > div > div {
        width: 100%;
      }
    }
  }
}
