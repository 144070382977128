.carga-manual-vin {
  .form {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 200px;
      }
    }

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .sipco-option-btn-icon {
      display: flex;
      flex-direction: column;
      gap: 5px;
      max-width: 400px;
    }

    .dropdown-button-container {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .dropdown-button-container .p-dropdown {
      flex: 1;
    }

    .required {
      color: red;
      margin-left: 4px;
    }
  }

  fieldset {
    margin-bottom: 5px;
  }
}
