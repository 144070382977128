.contabilizacion-anulacion {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;


 


    .form-row {
      display: flex;
      gap: 10px;
      align-items: flex-end; /* Garante que os itens se alinhem no topo */


      .checkBox-spico{
        display: flex;
        flex-direction: column;

        .checkbox{
          display: flex;
          margin-top: 40px;
          margin-bottom: 10px;
          gap: 10px;
          align-items: center;
        }
      }

      .sipco-option, .sipco-calendar {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 1;
        max-width: 300px; /* Consistência de largura */
      }

      .calendar {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-top: 25px;
        max-width: 300px;
      }

      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
