.tranferencia-playa {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

  .p-fieldset-legend {
    // Edita o estilo do header do fieldset
    width: 20%; // tamanho da caixa
    font-size: 1.3rem; // tamanho da fonte
  }

  .filter-options {
    display: flex;
    flex-direction: column; /* Faz com que as linhas fiquem uma em cima da outra */
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row; /* Mantém os elementos dentro da linha em linha */
      width: 100%;
      gap: 2rem; /* Ajuste o gap conforme necessário */
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }
    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 62%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }
    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      label {
        gap: 0.5rem;
      }
    }
    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      align-items: flex-end;
    }
    .sipco-option-no-label {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 15%;
      flex-shrink: 1;
      flex-basis: 15%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      label {
        gap: 0.5rem;
      }
    }
  }

  .tags {
    display: inline-flex;
    padding: 0.05rem;
    gap: 1rem;
    align-items: flex-end;
    .p-tag {
      font-size: 1rem;
    }
  }

  .chips {
    display: flex;
    .sipco-chip {
      flex-direction: row;
      padding: 0.05rem;
      gap: 1rem;
      .p-chip {
        margin-left: 1rem;
        min-width: 5rem;
      }
    }
  }
  .RadioButtonFilter {
    display: flex;
    align-items: center;    
  }

  .p-datatable {
    display: grid;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-width: 0 0 1px 1px;
    max-width: 150px;
  }

  .row-bloqueante {
    background-color: #ffffff;
    color: #800040; /* Ajuste a cor do texto para melhor contraste, se necessário */
  }
}
