.mantener-permisos-calidad {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 200px;
      }
    }

    .sipco-check {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .checkbox-label {
      margin-right: 5px;
    }
  }
}
