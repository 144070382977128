.modalModificarReparacionServiciosProveedores {
  .modal-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
    margin-bottom: 15px;

    .form-modal {
      display: flex;
      justify-content: center; /* Deixa um lado esquerdo e outro direito */
      gap: 30px; /* Espaço entre as colunas */

      .form-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .input-modal {
        width: 250px;
      }
    }

  }
}
