.despacho-man {
  .form {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 10px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;
      width: max-content;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
