.ingreso-ecom-danios-ford {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

      .sipco-option-btn {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 160px;
        margin-top: 20px;
      }
    }
  }

  // tags
  .tags {
    margin-top: 15px;

    .tag-registros-con-errores {
      margin-inline-end: 5px;
      background-color: #ff0000;
      color: #fff;
    }
    .tag-registros-con-advertencia {
      margin-inline-end: 5px;
      background-color: #0000ff;
      color: #fff;
    }
  }
}
