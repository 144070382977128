.informa-transportista {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;


    .sp-option{
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

   

      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }

  .filter-options {
    display: flex;
    flex-direction: column;
    /* Faz com que as linhas fiquem uma em cima da outra */
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row;
      /* Mantém os elementos dentro da linha em linha */
      width: 100%;
      gap: 2rem;
      /* Ajuste o gap conforme necessário */
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}