.administracion-remitos-a-concesionarios {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
        
      }
      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }

  .tags {
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    gap: 20px;
    justify-content: center;
  } 

  .search-action {
    justify-self: flex-end;
    flex-direction: column-reverse;
    
  }

}
