:global(.p-divider.p-divider-horizontal) {
  margin: 0 !important;
}
.sipco-filter {
  .p-accordion-header-text {
    display: flex;
    align-items: center;
    height: 3rem;
  }
  .p-accordion-header-link > span > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .p-accordion-header-link {
    padding: 0.5rem 1.5rem;
  }
  .filter-options {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: stretch;

    &.collumn {
      flex-direction: column;
    }

    .filter-row {
      display: flex;
      flex-direction: row;
      flex: 1;
      gap: 2rem;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 20%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }
      label {
        gap: 0.5rem;
      }
    }
    .sipco-teste {
      display: flex;
      gap: 1rem;
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .buttons {
    margin-top: 2rem;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
