.ingreso-mov-manuales {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 400px;
      }

      .sipco-option-btn {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 160px;
        margin-top: 20px;
      }

      .tag-sipco-option {
        display: flex;
        gap: 8px;
        justify-content: space-between;
      }
    }
  }

  fieldset {
    margin-bottom: 10px;
  }

  // tags
  .tags {
    margin-top: 15px;

    .tag-registros-erroneos {
      margin-inline-end: 5px;
      background-color: #ff0000;
      color: #fff;
    }
    .tag-registros-existentes {
      margin-inline-end: 5px;
      background-color: #193f18;
      color: #fff;
    }
  }
}
