.disponibilidad-importacion {
  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row;
      width: 70%;
      gap: 2rem;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 48%; /* Ajusta o espaço para dividir igualmente */
      max-width: 48%;
      gap: 1rem;
    }

    .sipco-option-row {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 6px;
      margin-bottom: -20px;
    }

    .sipco-option-row-cdu {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: -55px;
      margin-bottom: -40px;
    }
  }

  .models {
    display: flex;
    gap: 2rem;
    padding: 2rem;
    max-width: 70%;

    .sipco-options-column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 2rem;
      flex-basis: 25%; /* Cada coluna ocupa 25% da largura disponível */
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .chips {
    display: flex;
    .sipco-chip {
      flex-direction: row;
      padding: 1rem;
      gap: 2rem;
      .p-chip {
        margin-left: 1rem;
        min-width: 5rem;
      }
    }
  }
}
