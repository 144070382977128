.despacho-terceros {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

    //TODO: verificar com santiago outra alternativa
    .p-datatable {
      display: grid;
    }

  .p-fieldset-legend {
    width: 15%;
    font-size: 1.5rem;
  }
  .tags {
    display: inline-flex;
    gap: 1rem;
    align-items: flex-end;
    .p-tag{
      font-size: 1rem;
    }
  }
  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row;
      width: 70%;
      gap: 2rem;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 48%; /* Ajusta o espaço para dividir igualmente */
      max-width: 48%;
      gap: 1rem;
    }

    .divide {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 48%; /* Aproximadamente metade do espaço disponível */
      max-width: 48%; /* Garante que a .divide não ocupe mais que metade do espaço disponível */
      gap: 1rem; /* Espaço entre as .sipco-option-divide */

      .sipco-option-divide {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0.5rem; /* Espaço interno entre o conteúdo do .sipco-option-divide */
      }
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }

    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 62%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }

    .p-autocomplete-multiple-container.p-component.p-inputtext,
    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem;
      overflow: auto;
    }

    label {
      gap: 0.5rem;
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .filter-options-column {
    display: flex;
    flex-direction: row; /* Altera para colunas lado a lado */
    gap: 2rem;
    padding: 2rem;
    align-items: flex-start;

    .sipco-options-column {
      display: flex;
      flex-direction: column; /* Mantém os elementos dentro da linha em coluna */
      flex-grow: 1;
      width: 30%; /* Ajuste conforme necessário para ter 3 colunas */
      gap: 2rem; /* Ajuste o gap conforme necessário */
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }

    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 62%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 75%;
      flex-shrink: 1;
      flex-basis: 20%;

      .p-autocomplete-multiple-container.p-component.p-inputtext {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }

      .p-autocomplete-input.p-inputtext.p-component {
        width: 100%;
        height: 3rem; /* Ajuste para o tamanho desejado */
        overflow: auto;
      }

      label {
        gap: 0.5rem;
      }
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .RadioButtonFilter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  
}
