.peritaje {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: flex-start; /* Alinha os itens no topo */

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

      .radius-sipco {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
      }

      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      .tag-sipco-option {
        display: flex;
        gap: 8px;
        justify-content: space-between;
      }
    }
  }
}
