.modificacion-vin {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  gap: 10px;

  // Filtro no topo
  .filter-container {
    align-items: center;
  }

  // Painel que envolve o header e os cards
  .panel-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;

    // Header dentro do painel
    .panel-header {
      width: 100%;
      align-items: center;
      padding: 10px;
    }

    // Conteúdo abaixo do header que contém os cards
    .panel-content {
      flex: 1;
      display: flex;
      gap: 10px;

      .card-left {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .card-group {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 10px;

        .card {
          // flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;

    .form-row {
      display: flex;
      gap: 20px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

      .check-option {
        display: flex;
        flex-direction: row;
        gap: 10px;
        max-width: 500px;
        align-items: center;
      }

      .RadioButtonFilter {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}
