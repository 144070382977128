.vehiculos-pendiente-taller {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
    }
  }

  .tag-vehiculo-con-fecha-promesa-retrasada {
    background-color: #ff0000;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .dt-list-vehiculo-con-fecha-promesa-retrasada {
    color: #ff0000;
    font-weight: bold;
  }
}
