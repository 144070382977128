.GeracaoColectaPopUp {




}

.sipco-option {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    max-width: 300px;
  }

  .sipco-option-modal{
    display: flex;
  flex-direction: column;
  justify-content: left;
  padding-right: 25px;
  padding-bottom: 12px;
  max-width: 27%;
  gap: 3px;
  }

  .org-Hor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 67px;
    padding: 5px 0;
    gap: 15px;
    flex-wrap: wrap;
  }

  .org-Hor-left {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    min-height: 67px;
    padding: 5px 0;
    gap: 15px;
    flex-wrap: wrap;
  }

  .org-vert {
    display: flex;
    flex: 1;
    gap: 5px;
    max-width: 300px;
    height: 100%;
  }

  .red{
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    background-color: #ff6259;
  }

  .blue{
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    background-color: #71a8f1;
  }

  .dark-red{
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    background-color: #881337;
  }

  .custom-popup {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }