.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // background-color: #1b3055;
  font-size: 14px;

  .login-container-wrapper {
    display: flex;
    height: 545px;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 0;
    background: none;
    max-width: 930px;

    .login-background-img {
      display: flex;
    }

    .login-form {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      flex-direction: column;
      background: #fff;

      .form-wrapper {
        display: flex;
        height: 100%;
        margin: 70px;
        text-align: left;

        .form-body {
          display: flex;
          flex-direction: column;
          width: 305px;

          .login-header {
            margin-bottom: 20px;
            .login-title {
              display: inline-block;
              margin-bottom: 0.5rem;
              font-size: 28px;
              font-family: "Roboto", serif;
              font-weight: 700;
              color: #1b3055;
              text-transform: uppercase;
            }
          }
          div {
            display: flex;
          }
          .form {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .form-row {
              display: flex;
              flex-direction: column;

              .form-drop-down {
                margin-bottom: 10px;
              }

              .form-input {
                font-size: 11px;
                font-family: "Roboto", serif;
                color: #0c2856;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
              }

              .manage-access {
                display: flex;
                justify-content: space-between;
                gap: 40px;

                .access-wrapper-forgot-wrapper {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  align-content: center;
                  .password-recovery {
                    font-size: 8px;
                  }
                }
              }
            }
          }

          .body-actions {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .sign-in-button {
              background: #e52015;
              border-color: #fff;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
  .manage-access {
    display: flex;
    justify-content: space-between;
    gap: 65px;
  }

  .p-checkbox {
    display: flex;
    cursor: pointer;
    margin: 3px;
  }

  .p-checkbox-box {
    display: flex;
    border-radius: 2px;
    height: 15px;
    width: 15px;
  }
  .p-dropdown-panel * {
    width: 100%;
  }

  .footer-copyright {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    .footer {
      position: relative;
      top: 20px;

      .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: #ffffff;
        margin-bottom: 0;
        font-size: 10px;
        position: relative;
        bottom: 20px;

        .terms-conditions-link {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
  @media (max-width: 960px) {
    flex-direction: column;
    height: auto;
    padding: 20px;

    .login-container-wrapper {
      flex-direction: column;
      height: auto;
      max-width: 100%;
      .login-background-img {
        display: none;
      }

      .login-form {
        width: 100%;
        padding: 20px;

        .form-wrapper {
          margin: 20px;

          .form-body {
            width: 100%;

            .form {
              .form-row {
                margin-bottom: 10px;
              }
            }

            .body-actions {
              gap: 10px;

              .sign-in-button {
                padding: 8px 20px;
              }
            }
          }
        }
      }
    }

    .manage-access {
      gap: 20px;
    }

    .footer-copyright {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}
