$header-bg: linear-gradient(to right, #5998ce, #05133a);
$white: white;
$black: black;
$transparent: transparent;

.header-wrapper {
  header {
    .p-button {
      color: $white;
    }
    // background: $header-bg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    height: 50px;

    .logo,
    .login-info,
    .info:last-of-type {
      display: flex;
      align-items: center;
    }

    .logo {
      gap: 10px;

      p {
        margin: 0;
        padding: 10px;
        font-size: 19px;
        font-weight: bold;
        color: $white;
      }
    }

    img {
      width: 100px;
      height: 50px;
    }

    .info,
    .p-button.p-component {
      overflow: visible;
      font-size: 100%;
      margin: 0;
      padding: 0;
    }

    .info {
      p {
        font-size: 13px;
        margin: 0.2vw;

        color: $white;
      }

      &:last-of-type {
        margin-right: 1vw;
        z-index: 1004;
      }
    }

    .login-info {
      gap: 10px;
    }

    .p-button.p-component {
      background-color: $transparent;
      border: none;
      box-shadow: none;

      span {
        font-weight: 800;
        font-size: 13px ;
      }
    }

    .p-menuitem {
      display: flex;
      align-items: center;
      font-size: 13px;
      border: none;
      max-height: 100%;
      color: $black;
      background-color: var(--surface);
    }
    .p-menuitem * {
      width: 100%;
      .p-menuitem-icon {
        width: 15%;
      }
    }

    .p-menu.p-component.p-menu-overlay.p-ripple-disabled.p-connected-overlay-enter-done {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
    }

    .p-submenu-header {
      display: none;
    }
  }

  @media (max-width: 960px) {
    header {
      .info {
        display: none;
      }
    }
  }
}
