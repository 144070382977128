.modalAltaReparacionServicios {
  .modal-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
    max-width: 500px;
    justify-content: center; /* Centraliza verticalmente */
    align-items: center; /* Centraliza horizontalmente */

    .form-modal {
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: center;

      .form-row-modal {
        display: flex;
        gap: 10px;
        align-items: center;

        label {
          width: 100px; /* Define uma largura fixa para os labels */
          text-align: right; /* Opcional: alinha o texto à direita */
        }

        .input-modal {
          width: 250px;
          align-items: center;
          margin-right: 120px;
        }

        .check-box{
          margin-right: 100px;
        }

        .sipco-option-modal {
          display: flex;
          flex-direction: column;
          gap: 5px;
          flex: 1;
          max-width: 300px;
        }
      }
    }
  }
}
