.modalModificacionComprasTaller {
  .column-form {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1em;
    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .form-row {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        .sipco-option {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .input-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .input-modal {
          width: 200px;
        }

        .RadioButtonFilter {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }
  }
}
