.modalComprasTallerVer {
  .column-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vh; 
    .form {
      display: flex;
      flex-direction: column;
      gap: 25px;
      align-items: center;
      justify-content: center;

      .label-style {
        background-color: rgb(188, 188, 188);
        display: flex;
        text-align: center;
        justify-content: center;
        border-radius: 8px;
        width: 100%;
      }
      .form-row {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        .sipco-option {
          display: flex;
          flex-direction: column;
          gap: 5px;
          flex: 1;
          max-width: 300px;
          justify-content: center;
        }
      }

    }
  }
}
