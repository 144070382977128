.modal-content{
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
    .sipco-option {
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex: 1;
      width: 50%;
      max-width: 300px;
    }

}
.modal-footer{
   display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap : 10px;
  .sipco-option-number {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 30%;
  }
   .sipco-option {
     display: flex;
     flex-direction: column;
     gap: 5px;
     width: 70%;
   }

 }