//para editar o ConfirmDialog
.pi-exclamation-triangle {
  padding: 12px 20px;
  padding-right: 2px;
}

.p-dialog-footer {
  justify-content: flex-end;
}

//fin

.monitor-interfaces {

  .p-datatable-loading-overlay {
    background-color: rgba(240, 240, 240, 0.5);
  }

  .p-datatable-loading-icon {
    color: rgb(180, 180, 180);
    font-size: 2rem;
  }

  .card {
    display: flex;
    max-width: 400px;
    height: 100%;
  }

  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;

  }

  .p-fieldset-legend {
    width: 15%;
    font-size: 1.5rem;
  }

  .tags {
    display: inline-flex;
    gap: 1rem;
    align-items: flex-end;

    .p-tag {
      font-size: 1rem;
    }
  }

  .tiempo-atualization {
    display: flex;
    color: #B0B0B0;
    align-items: center;
    gap: 20px;
    padding-bottom: 30px;
    z-index: 10;
  }

  .sipco-option {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-right: 25px;
    max-width: 27%;
    gap: 6px;
  }

  .horizontal-strip {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .organizeHorizontally {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      padding-bottom: 25px;
      gap: 5px;
    }

  }


  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
    padding: 2rem;

    .sipco-options-line {
      display: flex;
      flex-direction: row;
      width: 70%;
      gap: 2rem;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 48%;
      /* Ajusta o espaço para dividir igualmente */
      max-width: 48%;
      gap: 1rem;
    }

    .divide {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 48%;
      /* Aproximadamente metade do espaço disponível */
      max-width: 48%;
      /* Garante que a .divide não ocupe mais que metade do espaço disponível */
      gap: 1rem;
      /* Espaço entre as .sipco-option-divide */

      .sipco-option-divide {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0.5rem;
        /* Espaço interno entre o conteúdo do .sipco-option-divide */
      }
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }

    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 62%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }

    .p-autocomplete-multiple-container.p-component.p-inputtext,
    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem;
      overflow: auto;
    }

    label {
      gap: 0.5rem;
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .filter-options-column {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 2rem;

    .p-dropdown-trigger {
      color: #fff;
      background: #3b82f6;
      border: 1px solid #3b82f6;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
    }

    .sipco-options-column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 2rem;
      flex-basis: 25%;
      /* Cada coluna ocupa 25% da largura disponível */
    }

    .divide {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      /* Ocupa todo o espaço da coluna */
      max-width: 100%;
      /* Garante que a .divide ocupe no máximo o mesmo espaço de uma .sipco-option */

      .sipco-option-divide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        flex-grow: 1;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 50%;
        /* Cada .sipco-option-divide ocupa 50% da largura da .divide */
      }
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 100%;
    }

    /* Estilos específicos para os elementos dentro das opções */
    .p-autocomplete-multiple-container.p-component.p-inputtext,
    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem;
      overflow: auto;
    }

    .p-calendar {
      width: 100%;
    }

    label {
      gap: 0.5rem;
    }

    .Dropdown {
      width: 100%;
    }
  }
}