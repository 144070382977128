.search-modal {
  .search-modal-button {
    border-radius: 1rem !important;
    border: solid 1px gray !important;
    width: 15rem !important;
    height: 3rem !important;
    color: white !important;
    font-weight: 400 !important;
  }

  .p-dialog-header-icon.p-dialog-header-close.p-link {
    display: none;
  }

  .SearchItem {
    width: 100%;
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid var(--surface-border);
  }
  .SearchItem:hover {
    background: var(--surface-hover);
  }

  .p-dialog-content {
    padding: 0;
  }

  .p-breadcrumb {
    border: none;
    margin: 0;
    padding: 0.5rem;
    gap: 0;
    background: none;

    .p-breadcrumb-list * {
      color: var(--surface-400);
    }
  }

  .icon-padding {
    padding-left: 1rem;
  }

  &.search-modal-dialog.p-dialog {
    padding: 0;
    position: absolute;
    width: 960px;
    height: 465px;

    .search-modal-no-result-text {
      text-align: center;
    }
  }

  .p-dialog-header {
    padding-bottom: 0;
  }

  .search-modal-input {
    width: 100%;
  }

  .search-modal-results {
    display: flex;
    flex-direction: column;

    h3 {
      margin-block-start: 0.5rem;
      margin-block-end: 0.5rem;
      margin-inline-start: 0.5rem;
      margin-inline-end: 0.5rem;
    }
    
    p {
      margin-block-start: 0.5rem;
      margin-block-end: 0.5rem;
      margin-inline-start: 0.5rem;
      margin-inline-end: 0.5rem;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .p-button-label {
    flex: 0 1 auto;
  }

  .p-dialog-footer {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem;
    border-top: 1px solid var(--surface-border);
  }
}
