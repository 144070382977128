.filter-security-options {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 20%;
  gap: 2rem;

  .option {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 20%;
    max-width: 20%;

    label {
      font-size: 1rem;
    }
  }


  .p-accordion-content {
    .p-accordion-content {
      display: flex;
      padding: 10px;
      flex-direction: row;
      width: 100%;

      .DivAcordion {
        padding: 0 2vw;

        label {
          margin-right: 10px;
        }
      }
    }
  }
}
