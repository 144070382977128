.liberacion-aduana {
  :global(.p-divider.p-divider-horizontal) {
    margin: 0 !important;
  }

  //TODO: verificar com santiago outra alternativa
  .p-datatable {
    display: grid;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-width: 0 0 1px 1px;
    max-width: 150px;
  }

  .p-chip-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .p-fieldset-legend {
    // Edita o estilo do header do fieldset
    width: 15%; // tamanho da caixa
    font-size: 1.3rem; // tamanho da fonte
  }

  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;

    .sipco-options-line {
      display: flex;
      flex-direction: row;
      width: 70%;
      gap: 2rem;
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 48%; /* Ajusta o espaço para dividir igualmente */
      max-width: 48%;
      gap: 1rem;
    }

    .divide {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 48%; /* Aproximadamente metade do espaço disponível */
      max-width: 48%; /* Garante que a .divide não ocupe mais que metade do espaço disponível */
      gap: 1rem; /* Espaço entre as .sipco-option-divide */

      .sipco-option-divide {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 0.5rem; /* Espaço interno entre o conteúdo do .sipco-option-divide */
        justify-content: flex-end;
        max-width: 30%;
      }
    }

    .sipco-option-extra-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 30%;
      flex-shrink: 1;
      flex-basis: 20%;
      justify-content: flex-end;
    }

    .sipco-option-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 62%;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: flex-end;
    }

    .p-autocomplete-multiple-container.p-component.p-inputtext,
    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem;
      overflow: auto;
    }

    label {
      gap: 0.5rem;
    }

    .RadioButtonFilter {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      align-items: flex-end;
    }
  }

  .filter-options-column {
    display: flex;
    gap: 2rem;
    padding: 2rem;
    max-width: 70%;

    // .p-dropdown-trigger {
    //   color: #fff;
    //   background: #3b82f6;
    //   border: 1px solid #3b82f6;
    //   transition:
    //     background-color 0.2s,
    //     color 0.2s,
    //     border-color 0.2s,
    //     box-shadow 0.2s;
    // }

    .sipco-options-column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 2rem;
      flex-basis: 25%; /* Cada coluna ocupa 25% da largura disponível */
    }

    .divide {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%; /* Ocupa todo o espaço da coluna */
      max-width: 100%; /* Garante que a .divide ocupe no máximo o mesmo espaço de uma .sipco-option */

      .sipco-option-divide {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
        flex-grow: 1;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 50%; /* Cada .sipco-option-divide ocupa 50% da largura da .divide */
      }
    }

    .sipco-option {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 100%;
    }

    /* Estilos específicos para os elementos dentro das opções */
    .p-autocomplete-multiple-container.p-component.p-inputtext,
    .p-autocomplete-input.p-inputtext.p-component {
      width: 100%;
      height: 3rem;
      overflow: auto;
    }

    .p-calendar {
      width: 100%;
    }

    label {
      gap: 0.5rem;
    }

    .Dropdown {
      width: 100%;
    }
  }

  .chips {
    display: flex;
    .sipco-chip {
      flex-direction: row;
      padding: 1rem;
      gap: 2rem;
      .p-chip {
        margin-left: 1rem;
        min-width: 5rem;
      }
    }
  }
}
