.ingreso-taller-manual {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: flex-start;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 400px;
      }

      .sipco-option-btn {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 160px;
        margin-top: 20px;
      }

      .tag-sipco-option {
        display: flex;
        gap: 8px;
        justify-content: space-between;
      }
    }
  }

  .panels-flex {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;

    .carga-manual,
    .taller-destino {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 400px;
      }
    }

    .form-row-check {
      display: flex;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 220px;
      }
    }
  }
}
