.colecta {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }
    }
  }
  .tags {
    margin-bottom: 15px;
  }
  .dataTable-spacing {
    margin-bottom: 15px !important;
  }
  .tag-colecta-quitada-del-jumelage {
    margin-right: 5px;
    background-color: #438ba1;
  }
}
