.org-option {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 25px;
  min-width: 220px;
  padding-bottom: 15px;
  gap: 15px;
}

.org-option-right {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 14px;
  min-width: 220px;
  width: 100%;
  padding-bottom: 15px;
  gap: 15px;
}

.org-option-col {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding-right: 25px;
  min-width: 27%;
  padding-bottom: 15px;
  gap: 15px;
}

.organizeHorizontally {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 80px;
  gap: 5px;
  flex-wrap: wrap;
}

.organizeHorizontally-col {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
  padding-bottom: 15px;
  gap: 5px;
  flex-wrap: wrap;
}

.orgHori {
  display: flex;
  flex-direction: row;
}

.centralizar {
  padding-top: 2px;
  padding-left: 5px;
  color: red;
}


.limit-Width {
  display: flex;
  flex-direction: column;
  align-items: self-start;
}

.sipco-option-modal {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-right: 25px;
  padding-bottom: 12px;
  max-width: 27%;
  gap: 3px;
}



.centerOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cp-option-modal {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 12px;

}

.cp-option-modal-alignVert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.cp-org-option {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 25px;
  width: 150px;
  padding-bottom: 15px;
  gap: 15px;
}

.centerLeftRow {
  display: flex;
  justify-content: left;
}

.orgHor {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 8px;
  justify-content: space-between;

}

.p-datatable-loading-overlay {
  background-color: rgba(240, 240, 240, 0.5);
  z-index: 10;
}

.p-datatable-loading-icon {
  color: rgb(180, 180, 180);
  font-size: 2rem;
}
