.mantener-vales-de-repuestos {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: flex-start;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 400px;
      }
    }
  }
}

.mantener-vales-de-repuestos-dialog {
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .form-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .sipco-option {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        max-width: 300px;
      }

      .sipco-option-obs input {
        display: flex;
        gap: 5px;
        flex: 1;
        width: 200%;
        max-width: 300px;
        height: 120px;
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px;
  }
}
